.navBar {
    display:flex;
    justify-content:center;
    align-items:center;
    background-color: $orange-dark;
    & div{
      width:80%;
      display:flex;
      padding:0.5rem;
      height:1rem;
      a {
        text-decoration: none;
        margin-right:1.5rem;
        color:$orange-light;
        &.active{
          font-weight:bold;
        }
      }
    }
  }