.product {
    &-card{
      @include cardConfiguration(38rem);
      &-description{
        margin-top:1rem;
        margin-bottom:1rem;
        text-align: center;
        color:$grey-dark;
      }
    }
  }