@import 'variables','mixins','navbar','search','product','cart';

body{
  background-color: $orange-light;
  color:$grey-dark;
  box-sizing: border-box;
  height:100vh;
}

.spinner{
  margin-top:1.5rem;
}

.container{
  width:80%;
  margin: 0 auto;
  min-height: calc(100vh - 1rem);
  padding-top:1rem;
  display:flex;
  flex-direction:column;
  align-items:center;
}